<template>
  <v-card
    class="min-h-full mx-auto mt-8 common_background"
    :loading="loading"
    max-width="450"
    elevation="4"
  >
    <v-form ref="frmForgetPassword" class="d-flex flex-column align-start pa-4" @submit.prevent="onForgetPassword">
      <v-card-title>
        <h2>
          <b>{{ $t("Forgot Password") }}</b>
        </h2>
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="email"
          :label="$t('Email')"
          :rules="rules"
          :disabled="isSubmitted"
          hide-details="auto"
        />
        <v-row dense class="mt-8 text-center">
          <v-col cols="12">
            <v-btn type="submit" block color="green" class="text-capitalize"> Reset password</v-btn>
          </v-col>
        </v-row>
        <v-row dense class="text-center">
          <v-col>
            <span
              v-if="!!notificationDetail && notificationDetail.isNotification"
              :class="notificationDetail.notificationType==='error'? 'red--text':'green--text'"
            >
              {{ notificationDetail.message }}
            </span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import firebase from 'firebase'
import {
  FIREBASE_SEND_PASSWORD_RESET_EMAIL,
} from '@/store/account/action'
import { get, commit, dispatch } from 'vuex-pathify'

export default {
  name:'ForgotPassword',
  data() {
    return {
      loading: false,
      email: '',
      rules: [
        (value) => !!value || 'Required.',
        (value) => (value && value.length >= 3) || 'Min 3 characters',
      ],
      isSubmitted: false,
    }
  },
  computed:{
    ...get('account', ['notificationDetail']),
  },
  methods: {
    async onForgetPassword() {
      this.loading = true
      if (this.$refs.frmForgetPassword.validate()) {
        this.isSubmitted = true
        const payload = {
          email: this.email,
        }

        await dispatch(`account/${FIREBASE_SEND_PASSWORD_RESET_EMAIL}`, payload)
        this.loading = false
      }
    },
  },
}
</script>
